.csldatecontainer {
	border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 5px 5% 5px 5%;
    box-sizing: border-box;
}
.react-date-picker__wrapper {
	display: block;
    width: 100%;
}
.react-date-picker__inputGroup {	
    width: 80%;
    flex-grow: unset;
    display: inline-block;
    margin-left: 9px;
}
.react-date-picker__button {
	display: inline-block;
	float: right;
}
.react-date-picker__wrapper {
    border: none ! important;
    /* color: #515151 ! important; */
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    float: right;
}