table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: auto;
  }
  
  td {
    border: 1px solid #dddddd;
    text-align: left;
    height: 20px;
    width:auto;
  }
  th {
    border: 1px solid #dddddd;
    text-align: left;
    height: 20px;
    width:auto
  }